
































































































import { Component, Mixins, Watch } from 'vue-property-decorator';
// import * as qrcode from 'qrcode';
import { Route } from 'vue-router';
import { getReservationPaymentStatus, Reservation, ReservationPaymentStatus } from '@/services/reservations.service';
import { HomeRoute, LoginRoute, PaymentRoute, ReservationRoute } from '@/router/routes';
import Button from '@/components/ui/Button.vue';
import { payReservation } from '@/services/payment.service';
import { PaymentInstrument } from '@/models/paymentInstrument';
import { StripePaymentError, StripeMixin } from '@/mixins/stripe';
import Loading from '@/components/ui/Loading.vue';
import { HttpError } from '@/utils/api/errors';
import { AlertCircleIcon, HelpCircleIcon } from 'vue-feather-icons';
import Alert from '@/components/ui/Alert.vue';
import { AuthMixin } from '@/mixins/user/Auth.mixin';
import PaymentMethods from '@/components/user/payment/PaymentMethods.vue';
import { GtagMixin } from '@/mixins/googleAnalytics/gtag.mixin';
import store from '@/store';
import userService from '@/services/user.service';
import { Permissions } from '../models/roles';

@Component({
  components: {
    PaymentMethods,
    Loading,
    Button,
    HelpCircleIcon,
    Alert,
    AlertCircleIcon
  }
})
export default class Payment extends Mixins(StripeMixin, AuthMixin, GtagMixin) {
  // set to true by default, because it annoyingly flickers on load. Was set to true at the beginning of mounted and is set to false in the mounted .finally{} anyway
  public isLoadingPaymentStatus = true;
  public loadingPaymentFailed = false;
  public selectedPaymentInstrumentId: string | undefined;
  public reservationPaymentStatus: ReservationPaymentStatus | null = null;

  private reservation: Reservation | null = null;
  private payAgainLoading = false;
  private loadingText = 'Komunikujeme s platební bránou';

  public isAdmin = false;

  get isPaid() {
    return this.reservationPaymentStatus?.recentPayment?.paymentStatus === 'PAID';
  }

  get isFailed() {
    /**
     * TODO Eventually add some time buffer here for CREATED status in case user will load this page
     *      before the actual payment will be processed
     */
    return (
      !this.reservationPaymentStatus ||
      ['CREATED', 'CANCELLED'].includes(this.reservationPaymentStatus?.recentPayment?.paymentStatus || '')
    );
  }

  get isActive() {
    return this.reservationPaymentStatus?.recentPayment?.active;
  }

  get isPaidByTransfer() {
    return this.reservationPaymentStatus?.paymentMethod === 'transfer';
  }

  get isPaidByCard() {
    return this.reservationPaymentStatus?.paymentMethod === 'card';
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange(toRoute: Route, fromRoute: Route) {
    store.dispatch('setSelectedCar', null);
    if (fromRoute && fromRoute.name === PaymentRoute && toRoute.name === ReservationRoute) {
      this.$router.push({ name: HomeRoute });
    }
  }

  get orderIdParam() {
    return this.$route.params.reservationId;
  }

  get blowfishParam() {
    return this.$route.params.blowfish;
  }

  get userHasAccessToViewPaymentStatus(): boolean {
    if (!this.loggedUser || !this.reservationPaymentStatus) {
      return false;
    }
    return this.loggedUser.id === this.reservationPaymentStatus.reservation.userId || this.isAdmin;
  }

  get loginRoute() {
    return LoginRoute;
  }

  public redirectToLogin() {
    this.$router.push({ name: LoginRoute, query: { login: '1' } });
  }

  get loggedUser() {
    return store.state.loggedUser;
  }

  @Watch('loggedUser')
  async assignAdminStatus() {
    this.isAdmin = await userService.isAllowedTo(Permissions.adminSection);
  }

  async mounted() {
    this.fetchReservationPaymentStatus();
  }

  async fetchReservationPaymentStatus() {
    try {
      this.loadingPaymentFailed = false;
      this.reservationPaymentStatus = await getReservationPaymentStatus(this.orderIdParam, this.blowfishParam);
      if (!this.isPaid && !this.isFailed) {
        this.startPaymentStatusPolling();
      }
    } catch (e) {
      if (e instanceof HttpError && (e.statusCode === 401 || e.statusCode === 403)) {
        // We expect user to login afterwards
        this.loadingPaymentFailed = false;
        return;
      } else if (e instanceof HttpError && e.statusCode == 404) {
        await this.$router.push({ name: HomeRoute });
        this.$toast.error('Rezervace nebyla nalezena, ujistěte se, že přistupujete na správnou adresu.');
      } else {
        console.error(e);
        this.$toast.error('Nastala nějaká neočekáváná chyba, zkuste stránku navštívit později.');
      }
      this.loadingPaymentFailed = true;
    } finally {
      this.isLoadingPaymentStatus = false;
    }
  }

  // Actually it's "instance" of timing interval
  private pollingInterval!: number;

  startPaymentStatusPolling() {
    if (!this.pollingInterval) {
      this.pollingInterval = window.setInterval(async () => {
        try {
          if (!this.orderIdParam) {
            this.stopPaymentStatusPolling();
            return;
          }
          this.reservationPaymentStatus = await getReservationPaymentStatus(this.orderIdParam, this.blowfishParam);
          if (this.isPaid || this.isFailed) {
            this.stopPaymentStatusPolling();
          }
        } catch (e) {
          console.error(e);
        }
      }, 3_000);
    }
  }

  stopPaymentStatusPolling() {
    this.pollingInterval && window.clearInterval(this.pollingInterval);
  }

  handleStoredPaymentInstrumentChanged(data: PaymentInstrument | null) {
    this.selectedPaymentInstrumentId = data?.paymentInstrumentData?.externalId;
  }

  async payAgain() {
    if (!this.selectedPaymentInstrumentId) {
      return;
    }
    this.reservationPaymentStatus = await getReservationPaymentStatus(this.orderIdParam, this.blowfishParam);
    if (this.isPaid) {
      this.$toast.success('Rezervace již byla úspěšně zaplacena.');
      return;
    } else if (!this.isActive) {
      this.$toast.error(
        'Pokoušíte se zaplatit neaktivní rezervaci. Zkontrolujte si emailovou schránku a případně kontaktujte naši infolinku.'
      );
      return;
    }
    this.loadingText = 'Komunikujeme s platební bránou';
    this.payAgainLoading = true;
    try {
      const { clientSecret } = await payReservation(this.orderIdParam, this.selectedPaymentInstrumentId);
      await this.confirmPayment(clientSecret, this.orderIdParam);
      this.reservationPaymentStatus = await getReservationPaymentStatus(this.orderIdParam, this.blowfishParam);
      if (!this.isPaid || !this.isFailed) {
        this.startPaymentStatusPolling();
      }
    } catch (error) {
      this.handleConfirmPaymentError(error as Error | StripePaymentError);
    } finally {
      this.loadingText = 'Potvrzujeme rezervaci';
      this.payAgainLoading = false;
    }
  }
}
