export enum Permissions {
  adminSection = 'adminSection',
  additionalServicesView = 'additionalServicesView',
  blacklistManage = 'blacklistManage',
  blacklistView = 'blacklistView',
  blacklistDelete = 'blacklistDelete',
  carsView = 'carsView',
  carsViewAdmin = 'carsViewAdmin',
  carsCreate = 'carsCreate',
  carsManage = 'carsManage',
  carsLockManage = 'carsLockManage',
  carsViewLocation = 'carsViewLocation',
  carsRepairView = 'carsRepairView',
  carsRepairManage = 'carsRepairManage',
  documentsViewAdmin = 'documentsViewAdmin',
  documentsViewOwn = 'documentsViewOwn',
  documentsManageOwn = 'documentsManageOwn',
  parkingViewAdmin = 'parkingViewAdmin',
  parkingView = 'parkingView',
  reservationView = 'reservationView',
  reservationCalendarView = 'reservationCalendarView',
  reservationPayment = 'reservationPayment',
  reservationReturnformManage = 'reservationReturnformManage',
  reservationPickupformManage = 'reservationPickupformManage',
  reservationReturnformFill = 'reservationReturnformFill',
  reservationPickupformFill = 'reservationPickupformFill',
  reservationCreateOwn = 'reservationCreateOwn',
  reservationManage = 'reservationManage',
  reservationInvoiceManage = 'reservationInvoiceManage',
  reservationInvoiceView = 'reservationInvoiceView',
  reservationRefundsManage = 'reservationRefundsManage',
  reservationRefundsView = 'reservationRefundsView',
  statusView = 'statusView',
  userViewAdmin = 'userViewAdmin',
  userLoginRegistration = 'userLoginRegistration',
  userViewSelf = 'userViewSelf',
  userManageSelf = 'userManageSelf',
  userManage = 'userManage',
  userDocumentDelete = 'userDocumentDelete',
  rolesView = 'rolesView',
  rolesManage = 'rolesManage',
  rolesDelete = 'rolesDelete',
  userRolesView = 'userRolesView',
  userRolesManage = 'userRolesManage',
  userCreateAdmin = 'userCreateAdmin',
  paymentPay = 'paymentPay',
  paymentViewOwn = 'paymentViewOwn',
  paymentCreate = 'paymentCreate',
  additionalPaymentCreate = 'additionalPaymentCreate',
  feesView = 'feesView'
}
